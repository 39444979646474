import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
// import {  subscribeOn } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { Products } from 'src/app/core/constant/product.constants';
import { LOCAL_STORAGE } from 'src/app/core/constant/local-storage.constants';
import { API } from 'src/app/core/constant/api.constants';
import { DatePipe } from '@angular/common';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    count: number;
    constructor(private authenticationService: AuthenticationService) { }
    datePipe= new DatePipe('en-Us');
    private pendingRequests = new Map<string, Subject<void>>();

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                'X-App-id': Products.RevenueCycleManagement.toString()
            },
        });
        const token = this.authenticationService.user.access_token;
        // const token = this.authenticationService.tokenValue;
        

            // let currentDate= new Date()
            // const expirationDate = this.helper.getTokenExpirationDate(token.token);
            // let timestamp= (expirationDate.getTime()-currentDate.getTime())/60000
            // this.count = 0;

            // if (timestamp<5&&timestamp>0) {
            //     if (localStorage.getItem('refreshFlag')) {
                    
            //     }else{
            //         localStorage.setItem('refreshFlag','done')
            //         this.authenticationService.refreshToken(token).subscribe(res=>{});
            //     }
            // }else{
            //     localStorage.removeItem('refreshFlag')
            // }
    
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                },
            });
        

          const cancel$ = new Subject<void>();
          const url = request.urlWithParams;
      
          // Check if a previous request for the same URL exists
          const previousCancel$ = this.pendingRequests.get(url);
      
          // If a previous request exists, cancel it
          if (previousCancel$) {
            previousCancel$.next();
          }
      
          // Store the new cancel subject for this request
          this.pendingRequests.set(url, cancel$);
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
              // Handle errors here
              return throwError(error);
            }),
            takeUntil(cancel$), // Cancel the request if a new one for the same URL is made
            filter(event => event instanceof HttpResponse) // Only complete the cancel subject for successful responses
          ).pipe(
            tap(() => {
              this.pendingRequests.delete(url); // Remove the cancel subject for this URL
            })
          );
    }
}
